<template>
  <div>
    <div class="home-v2-intro">
      <div class="home-v2-intro__head">
        <nuxt-link
          v-if="page.latest_release_blog_post"
          :to="{
            name: 'blog-post-page',
            params: {
              blogPostSlug: page.latest_release_blog_post.slug,
            },
          }"
          class="home-v2-intro__release"
        >
          <div class="home-v2-intro__release-new">New</div>
          <div class="home-v2-intro__release-text">
            Baserow v{{ page.latest_release_blog_post.release_version }} is now
            available
          </div>
          <div class="home-v2-intro__release-icon">
            <i class="iconoir-nav-arrow-right"></i>
          </div>
        </nuxt-link>
        <h1 class="home-v2-intro__title">{{ page.intro_title }}</h1>
        <div class="home-v2-intro__description">
          {{ page.intro_description }}
        </div>
        <div class="home-v2-intro__actions">
          <CmsLink
            v-for="button in page.intro_buttons"
            :key="button.id"
            :url-or-path="button.value.url_or_path"
            :class="`button button--${button.value.type} button--large`"
          >
            {{ button.value.text }}
          </CmsLink>
        </div>
        <div class="home-v2-intro__self-host">
          Prefer to self host?
          <nuxt-link
            :to="{
              name: 'dev-docs',
              params: {
                path: 'installation/install-on-cloudron',
              },
            }"
            title="Deploy Baserow to your own Cloudron instance"
            >Cloudron</nuxt-link
          >,
          <nuxt-link
            :to="{
              name: 'dev-docs',
              params: {
                path: 'installation/install-with-docker',
              },
            }"
            title="Install with Docker"
            >Docker</nuxt-link
          >,
          <nuxt-link
            :to="{
              name: 'dev-docs',
              params: {
                path: 'installation/install-on-aws',
              },
            }"
            title="Manually install Baserow on AWS step by step"
            >AWS</nuxt-link
          >, or
          <nuxt-link
            :to="{
              name: 'dev-docs',
              params: {
                path: 'installation/install-on-heroku',
              },
            }"
            title="Manually install Baserow on Heroku step by step"
            >Heroku</nuxt-link
          >
        </div>
      </div>
      <div class="home-v2-intro__screenshots">
        <img
          v-if="page.intro_image"
          :src="page.intro_image.url"
          :alt="page.intro_image.alt"
          :srcset="`${page.intro_image_retina.url} 2x`"
          class="home-v2-intro__screenshots-image"
        />
      </div>
    </div>
    <div class="home-v2-logos">
      <div class="home-v2-logos__text">
        {{ page.logos_text }}
      </div>
      <ul class="home-v2-logos__list">
        <li v-for="image in page.logos_images" :key="image.id">
          <img
            v-if="image.value.image"
            :src="image.value.image.src"
            :alt="image.value.image.alt"
            :srcset="`${image.value.retina_image.src} 2x`"
            loading="lazy"
          />
        </li>
      </ul>
    </div>
    <div class="home-v2-badges">
      <div class="home-v2-badges__inner">
        <div
          v-for="badge in page.logos_badges"
          :key="badge.id"
          class="home-v2-badges__badge"
          :class="{
            'home-v2-badges__badge--vertical':
              badge.value.image.image.height > badge.value.image.image.width,
          }"
        >
          <a :href="badge.value.url" target="_blank">
            <img
              v-if="badge.value.image"
              :src="badge.value.image.image.src"
              :alt="badge.value.image.image.alt"
              :srcset="`${badge.value.image.retina_image.src} 2x`"
              loading="lazy"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="saas-section">
      <div class="saas-section__title">
        <h2 class="saas-striped-title">
          {{ page.connect_title }}
        </h2>
      </div>
      <div class="saas-section__description">
        {{ page.connect_text }}
      </div>
      <div class="saas-section__image">
        <img
          v-if="page.connect_image"
          :src="page.connect_image.url"
          :alt="page.connect_image.alt"
          :srcset="`${page.connect_image_retina.url} 2x`"
          loading="lazy"
        />
      </div>
    </div>
    <div class="saas-section saas-section--light-to-dark">
      <div class="saas-section__title saas-section__title--wide">
        <h2 class="saas-striped-title">
          {{ page.views_title }}
        </h2>
      </div>
      <div class="saas-section__description">
        {{ page.views_text }}
      </div>
      <div class="home-v2-views">
        <SasSSegmentControl
          class="home-v2-views__segment-control"
          :segments="segments"
          :selected-segment-index="selectedSegmentIndex"
          @select-segment="handleSelectedSegment"
        ></SasSSegmentControl>
        <div class="home-v2-views__screenshots-wrapper">
          <div class="home-v2-views__screenshots">
            <div
              class="home-v2-views__screenshots-carousel"
              :style="{ transform: `translateX(-${selectedSegmentIndex}00%)` }"
            >
              <div
                v-for="segment in segments"
                :key="segment.name"
                class="home-v2-views__screenshot"
              >
                <img
                  :src="segment.image"
                  :alt="segment.name"
                  :srcset="`${segment.retinaImage} 2x`"
                  class="home-v2-views__screenshot-image"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="saas-section saas-section--dark-to-light">
      <div class="saas-section__title saas-section__title--wide">
        <h2 class="saas-striped-title">{{ page.airtable_title }}</h2>
      </div>
      <div class="saas-section__description">
        {{ page.airtable_text }}
      </div>
      <PageIconGrid
        :icons="page.airtable_items.map((item) => item.value)"
      ></PageIconGrid>
      <div class="home-v2-boxes">
        <PageBox
          v-for="box in page.usps_box_row_1.map((box) => box.value)"
          :key="box.id"
          :box="box"
        ></PageBox>
      </div>
      <div class="home-v2-boxes">
        <PageBox
          v-for="box in page.usps_box_row_2.map((box) => box.value)"
          :key="box.id"
          :box="box"
        ></PageBox>
      </div>
      <div class="saas-section__actions">
        <CmsLink
          v-for="button in page.usps_buttons"
          :key="button.id"
          :url-or-path="button.value.url_or_path"
          :class="`button button--${button.value.type} button--large`"
        >
          {{ button.value.text }}
        </CmsLink>
      </div>
    </div>
    <div class="saas-section saas-section--dark-to-light">
      <div class="saas-section__title saas-section__title--wide">
        <h2 class="saas-striped-title">{{ page.grow_title }}</h2>
      </div>
      <div class="saas-section__description">
        {{ page.grow_text }}
      </div>
      <PageIconGrid
        :icons="page.grow_items.map((item) => item.value)"
      ></PageIconGrid>
    </div>
    <div
      class="saas-section saas-section--no-padding-bottom saas-section--dark"
    >
      <div class="saas-section__title">
        <h2 class="saas-striped-title saas-striped-title--light">
          {{ page.integrate_title }}
        </h2>
      </div>
      <div class="saas-section__description saas-section__description--light">
        {{ page.integrate_text }}
      </div>
      <div class="saas-section__actions">
        <CmsLink
          v-for="button in page.integrate_buttons"
          :key="button.id"
          :url-or-path="button.value.url_or_path"
          :class="`button button--ghost button--large`"
        >
          {{ button.value.text }}
        </CmsLink>
      </div>
      <div class="saas-section__dark-image">
        <img
          v-if="page.integrate_image"
          :src="page.integrate_image.url"
          :alt="page.integrate_image.alt"
          :srcset="`${page.integrate_image_retina.url} 2x`"
          class="saas-section__dark-image-src"
          loading="lazy"
        />
      </div>
    </div>
    <div class="saas-section saas-section--dark">
      <div class="saas-section__title">
        <h2 class="saas-striped-title saas-striped-title--light">
          {{ page.comparison_title }}
        </h2>
      </div>
      <div class="saas-section__description saas-section__description--light">
        {{ page.comparison_text }}
      </div>
      <div class="home-v2-compare">
        <div class="home-v2-compare__product-wrapper">
          <div class="home-v2-compare__product">
            <div class="home-v2-compare__current">
              <div class="home-v2-compare__current-text">
                I'm currently using
              </div>
              <div class="home-v2-compare__current-dropdown">
                <div class="home-v2-compare__dropdown-wrapper">
                  <div
                    v-click-outside="outsideUsingDropdown"
                    class="home-v2-compare__dropdown"
                    :class="{
                      'home-v2-compare__dropdown--open': usingDropdownOpen,
                    }"
                  >
                    <a
                      class="home-v2-compare__dropdown-selected home-v2-compare__dropdown-selected--thick"
                      @click="usingDropdownOpen = !usingDropdownOpen"
                    >
                      <img
                        class="home-v2-compare__dropdown-selected-image"
                        :src="
                          page.comparison_competitors[selectedCompetitor].value
                            .logo.icon.src
                        "
                        loading="lazy"
                      />
                      <div class="home-v2-compare__dropdown-selected-text">
                        {{
                          page.comparison_competitors[selectedCompetitor].value
                            .name
                        }}
                      </div>
                      <i
                        class="home-v2-compare__dropdown-selected-icon iconoir-nav-arrow-down"
                      ></i>
                    </a>
                    <div class="home-v2-compare__dropdown-items">
                      <a
                        v-for="(
                          competitor, index
                        ) in page.comparison_competitors"
                        :key="competitor.id"
                        class="home-v2-compare__dropdown-item"
                        @click="
                          ;[
                            (selectedCompetitor = index),
                            (usingDropdownOpen = false),
                          ]
                        "
                      >
                        <img
                          :src="competitor.value.logo.icon.src"
                          loading="lazy"
                        />
                        {{ competitor.value.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="home-v2-compare__to">to</div>
            <div class="home-v2-compare__task">
              <div class="home-v2-compare__dropdown-wrapper">
                <div
                  v-click-outside="outsideToDropdown"
                  class="home-v2-compare__dropdown"
                  :class="{ 'home-v2-compare__dropdown--open': toDropdownOpen }"
                >
                  <a
                    class="home-v2-compare__dropdown-selected"
                    @click="toDropdownOpen = !toDropdownOpen"
                  >
                    <div class="home-v2-compare__dropdown-selected-text">
                      {{
                        page.comparison_use_cases[selectedUseCase].value.name
                      }}
                    </div>
                    <i
                      class="home-v2-compare__dropdown-selected-icon iconoir-nav-arrow-down"
                    ></i>
                  </a>
                  <div class="home-v2-compare__dropdown-items">
                    <a
                      v-for="(competitor, index) in page.comparison_use_cases"
                      :key="competitor.id"
                      class="home-v2-compare__dropdown-item"
                      @click="
                        ;[(selectedUseCase = index), (toDropdownOpen = false)]
                      "
                      >{{ competitor.value.name }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-v2-compare__separator"></div>
        <div class="home-v2-compare__vs-wrapper">
          <div class="home-v2-compare__vs">
            <div class="home-v2-compare__vs-product">
              <div class="home-v2-compare__vs-product-logo">
                <img
                  class="home-v2-compare__vs-product-logo-image"
                  src="@baserow/modules/core/static/img/logo-white.svg"
                  alt="Baserow logo"
                  loading="lazy"
                />
              </div>
              <div class="home-v2-compare__vs-product-usps-wrapper">
                <div
                  class="home-v2-compare__vs-product-usps home-v2-compare__vs-product-usps--dark"
                >
                  <div
                    v-for="(usp, index) in page.comparison_competitors[
                      selectedCompetitor
                    ].value.baserow_points"
                    :key="index"
                    class="home-v2-compare__vs-product-usp"
                  >
                    <i
                      class="home-v2-compare__vs-product-usp-icon iconoir-check-circle"
                    ></i>
                    {{ usp }}
                  </div>
                </div>
              </div>
            </div>
            <div class="home-v2-compare__vs-middle">
              <div class="home-v2-compare__vs-middle-circle-wrapper">
                <div class="home-v2-compare__vs-middle-circle">VS.</div>
              </div>
            </div>
            <div class="home-v2-compare__vs-product">
              <div class="home-v2-compare__vs-product-logo">
                <img
                  class="home-v2-compare__vs-product-logo-image"
                  :src="
                    page.comparison_competitors[selectedCompetitor].value.logo
                      .logo.src
                  "
                  loading="lazy"
                />
              </div>
              <div class="home-v2-compare__vs-product-usps-wrapper">
                <div class="home-v2-compare__vs-product-usps">
                  <div
                    v-for="(usp, index) in page.comparison_competitors[
                      selectedCompetitor
                    ].value.competitor_points"
                    :key="index"
                    class="home-v2-compare__vs-product-usp"
                  >
                    <i
                      class="home-v2-compare__vs-product-usp-icon iconoir-cancel"
                    ></i>
                    {{ usp }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="page.comparison_use_cases[selectedUseCase].value.template"
          class="home-v2-compare__template-container"
        >
          <div class="home-v2-compare__template-wrapper">
            <div class="home-v2-compare__template">
              <div class="home-v2-compare__template-details">
                <div class="home-v2-compare__template-icon-wrapper">
                  <div class="home-v2-compare__template-icon">
                    <i
                      :class="
                        page.comparison_use_cases[selectedUseCase].value
                          .template.icon
                      "
                    ></i>
                  </div>
                </div>
                <div class="home-v2-compare__template-name">
                  {{
                    page.comparison_use_cases[selectedUseCase].value.template
                      .name
                  }}
                </div>
                <div class="home-v2-compare__template-recommended">
                  Recommended
                </div>
              </div>
              <div class="home-v2-compare__template-actions">
                <Button
                  tag="nuxt-link"
                  :to="{
                    name: 'template-detail',
                    params: {
                      slug: page.comparison_use_cases[selectedUseCase].value
                        .template.slug,
                    },
                  }"
                  size="large"
                  >Start with this template</Button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="saas-section">
      <div class="saas-section__title">
        <h2 class="saas-striped-title">
          {{ page.customers_title }}
        </h2>
      </div>
      <PageQuotes
        :quotes="
          page.customers_quotes.map((quote) => {
            return quote.value
          })
        "
      ></PageQuotes>
    </div>
    <div class="saas-section saas-section--gray">
      <div class="saas-section__title saas-section__title--wide">
        <h2 class="saas-striped-title">{{ page.fans_title }}</h2>
      </div>
      <PageHorizontalMessages
        :message-rows="[
          page.fans_messages_row_1.map((message) => message.value),
          page.fans_messages_row_2.map((message) => message.value),
        ]"
      ></PageHorizontalMessages>
    </div>
    <div class="saas-section">
      <div class="saas-section__title saas-section__title--wide">
        <h2 class="saas-striped-title">{{ page.templates_title }}</h2>
        <div class="saas-section__description">
          {{ page.templates_text }}
        </div>
      </div>
      <PageTemplates
        :templates="page.templates_items.map((template) => template.value)"
      ></PageTemplates>
      <div class="saas-section__actions margin-bottom-0">
        <CmsLink
          v-for="button in page.templates_buttons"
          :key="button.id"
          :url-or-path="button.value.url_or_path"
          :class="`button button--${button.value.type} button--large`"
        >
          {{ button.value.text }}
        </CmsLink>
      </div>
    </div>
    <div class="saas-section home-v2-community saas-section--gray">
      <div class="saas-section__title">
        <h2 class="saas-striped-title">
          {{ page.community_title }}
        </h2>
        <div class="saas-section__description">
          {{ page.community_text }}
        </div>
      </div>
      <div class="saas-section__actions margin-bottom-0">
        <CmsLink
          v-for="button in page.community_buttons"
          :key="button.id"
          :url-or-path="button.value.url_or_path"
          :class="`button button--${button.value.type} button--large`"
        >
          {{ button.value.text }}
        </CmsLink>
      </div>
    </div>
    <FooterJoin
      :hide="page.hide_footer"
      :title="page.footer_title"
      :text="page.footer_text"
      :buttons="page.footer_buttons"
    ></FooterJoin>
  </div>
</template>

<script>
import cmsPage from '@saas/mixins/cmsPage'
import { getBaserowDefaultOpenGraphImage } from '@saas/utils'
import SasSSegmentControl from '@saas/components/SasSSegmentControl'
import PageTemplates from '@saas/components/page/PageTemplates'
import PageHorizontalMessages from '@saas/components/page/PageHorizontalMessages'
import PageIconGrid from '@saas/components/page/PageIconGrid'
import PageBox from '@saas/components/page/PageBox'
import PageQuotes from '@saas/components/page/PageQuotes'
import FooterJoin from '@saas/components/footer/FooterJoin'
import CmsLink from '@saas/components/cms/CmsLink'

export default {
  components: {
    PageQuotes,
    PageIconGrid,
    PageTemplates,
    PageBox,
    SasSSegmentControl,
    PageHorizontalMessages,
    CmsLink,
    FooterJoin,
  },
  mixins: [cmsPage],
  layout: 'saas',
  async asyncData(context) {
    const { app, error } = context
    try {
      const page = await cmsPage.asyncData(context)
      await app.store.dispatch('header/setTopBars', page.page.top_bars)
      return page
    } catch (e) {
      return error({
        statusCode: 400,
        message: 'Something went wrong.',
      })
    }
  },
  data() {
    return {
      usingDropdownOpen: false,
      toDropdownOpen: false,
      selectedCompetitor: 0,
      selectedUseCase: 0,
      selectedSegmentIndex: 0,
    }
  },
  head() {
    const head = cmsPage.head.call(this)
    Object.assign(head, {
      meta: [
        {
          name: 'keywords',
          content:
            'database, online database, open source, web application, database api, database application, spreadsheet application, data collaboration, data platform, no-code, airtable, airtable alternative',
        },
        {
          property: 'og:locale',
          content: 'en_us',
        },
        {
          property: 'og:site_name',
          content: 'Baserow',
        },
        {
          property: 'og:title',
          content: 'Baserow - Open source no-code database',
        },
        {
          property: 'og:url',
          content: `${this.$config.PUBLIC_WEB_FRONTEND_URL}/`,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: getBaserowDefaultOpenGraphImage(this.$config),
        },
        {
          itemprop: 'name',
          content: 'Baserow - Get the most out of your data.',
        },
        {
          itemprop: 'headline',
          content: 'Baserow - Open source no-code database',
        },
        {
          itemprop: 'author',
          content: 'Baserow',
        },
        ...head.meta,
      ],
    })
    return head
  },
  computed: {
    segments() {
      return this.page.views_items.map((view) => {
        return {
          name: view.value.name,
          icon: view.value.icon_class,
          activeIconColor: {
            blue: 'color-primary',
            red: 'color-error',
            green: 'color-success',
            yellow: 'color-warning',
          }[view.value.color],
          image: view.value.screenshot.image.src,
          retinaImage: view.value.retina_screenshot.image.src,
        }
      })
    },
  },
  mounted() {
    this.selectNextViewInterval = setInterval(() => {
      const beforeIndex = this.selectedSegmentIndex
      const afterIndex =
        this.selectedSegmentIndex >= this.segments.length - 1
          ? 0
          : this.selectedSegmentIndex + 1

      if (beforeIndex !== afterIndex) this.selectedSegmentIndex = afterIndex
    }, 5000)
  },
  async beforeDestroy() {
    await this.$store.dispatch('header/setTopBars', [])
  },
  methods: {
    handleSelectedSegment(index) {
      this.selectedSegmentIndex = index
      clearInterval(this.selectNextViewInterval)
    },
    outsideUsingDropdown() {
      this.usingDropdownOpen = false
    },
    outsideToDropdown() {
      this.toDropdownOpen = false
    },
  },
}
</script>
